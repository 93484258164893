import * as React from "react"
import PageLayout from "../../components/pageLayout";

const TakkPage = () => {
    return (
        <PageLayout>
            <div className="bg-secondary-light py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
                <div className="relative max-w-xl mx-auto">
                    <div className="text-center">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-100 sm:text-4xl">
                            Takk for din henvendelse
                        </h2>
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default TakkPage
